import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
// import reportWebVitals from './reportWebVitals'

import HomePage from './pages/HomePage'
import NoMatchPage from './pages/NoMatchPage'

import './styles/global.scss'
import './pages/pages.scss'
import './components/layout/layout.scss'
import './components/common/parallax/parallax.scss'
import './components/terms/terms.scss'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
	<React.StrictMode>
		<Router>
            <Routes>
                <Route index
                    element={<HomePage />} />
                <Route path='*' element={<NoMatchPage />} />
            </Routes>
        </Router>
	</React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
